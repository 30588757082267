import axios from 'axios'
import store from './store'

export const checkLogin = () => {
  const token = store.state.token
  if(!token) {
    return false;
  }
  else{
    return true;
  }
}

console.log(process.env.VUE_APP_API_URL)
const userRequest = axios.create({
  baseURL: process.env.VUE_APP_API_URL
})
userRequest.interceptors.request.use(config => {
  const token = store.state.token
  token && (config.headers.Authorization = 'Bearer' + token)
  return config
}, (error) => {
  return Promise.reject(error)
})

userRequest.interceptors.response.use(function (response) {
  if(response.data.message == 'logout') {
    store.dispatch('logout')
    window.location.href = '/'
  }
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error)
})

// 首頁
export const indexHeader = data => userRequest.get('/api/front/top_href/get', data)
export const indexBanner = data => userRequest.get('/api/front/banner/search', data)
export const indexCategory = data => userRequest.get('/api/front/category/search', data)
export const indexFast = data => userRequest.get('/api/front/fastoder/get', data)
export const indexStatic = data => userRequest.get('/api/front/static/search', data)
export const indexSingleimage = data => userRequest.get('/api/front/singleimage/get', data)
export const indexRecommen = data => userRequest.get('/api/front/recommen/search', data)
export const indexTwiceimage = data => userRequest.get('/api/front/twiceimage/search', data)
export const indexSearchthree = data => userRequest.get('/api/front/new/searchthree', data)
export const indexSort = data => userRequest.get('/api/front/home/search', data)
export const indexHot = data => userRequest.get('/api/front/hot/search', data)
export const indexVideo = data => userRequest.get('/api/front/video/get', data)
//liff
export const liffLogin = data => userRequest.post('/api/auth/liff_login', data)
// export const liffCheckIsLogin = data => userRequest.post('/api/auth/liff_login', data)

export const contentData = data => userRequest.get('/api/front/home/desc', data)
// 最新消息
export const newsAll = data => userRequest.get('/api/front/new/search', data)
export const newsData = data => userRequest.get('/api/front/new/get', data)
// 最新活動
export const activityAll = data => userRequest.get('/api/discount/search', data)
export const activitysData = data => userRequest.get('/api/discount/get', data)
// 產品
export const productCategory = data => userRequest.get('/api/category/search', data)
export const productList = data => userRequest.get('/api/category/find', data)
export const productDetail = data => userRequest.get('/api/product/get', data)
// 登入註冊
export const login = data => userRequest.post('/api/auth/login', data)
export const regist = data => userRequest.post('/api/register/user/store', data)
export const registDealer = data => userRequest.post('/api/register/dealer/store', data)
export const registCheckRef = data => userRequest.post('/api/auth/verify/refcode', data)
export const ckeckUser = data => userRequest.get('/api/user/verifyMonthShip', data)
export const sendCaptcha = data => userRequest.post('/api/register/user/sendCaptcha', data)
// 購物車
export const joinCart = data => userRequest.post('/api/cart/store', data)
export const getCart = data => userRequest.post('/api/cart/search', data)
export const delCart = data => userRequest.post('/api/cart/delete', data)
export const updateCart = data => userRequest.post('/api/cart/update', data)
// 忘記密碼
export const forgetPassword = data => userRequest.post('/api/user/password/forget', data)
export const changePassword = data => userRequest.post('/api/user/password/update', data)
// 聯絡我們
export const contactUs = data => userRequest.post('/api/contactu/email', data)
// 我的最愛
export const addLike = data => userRequest.post('/api/user/like/store', data)
export const getLike = data => userRequest.post('/api/user/like/search', data)
export const delLike = data => userRequest.post('/api/user/like/delete', data)
// 購物結帳
export const orderApi = data => userRequest.post('/api/order/pay_info', data)
export const finallOrder = data => userRequest.post('/api/order/store', data)
// 會員中心 - 通知
export const memberNotice = data => userRequest.post('/api/notification/search', data)
// 會員中心 - 訂單
export const memberOrder = data => userRequest.post('/api/order/search', data)
export const memberOrderDetail = data => userRequest.post('/api/order/detail', data)
export const memberOrderUp = data => userRequest.post('/api/order/paymentTransfer', data)
export const htcDetail = data => userRequest.get('/api/order/hct_detail', data)
export const map = data => userRequest.post('/api/ecpay/mapCreateToken', data)
export const cancelOrder = data => userRequest.post('/api/order/cancelOrder', data)
export const changeStratchCard = data => userRequest.post('/api/order/changeStratchCard', data)

export const repayTappays = data => userRequest.post('/api/order/repayTappays', data)

// 第三方登入
export const threeLogin = data => userRequest.post('/api/threeway/login', data)
export const threeBind = data => userRequest.post('/api/user/threeway/bind', data)
export const threeWayLoginBind = data => userRequest.post('/api/auth/threeWayLoginBind', data)
// 會員中心 - 抽獎券
export const memberRaffle = data => userRequest.post('/api/raffle/search', data)
// 會員中心 - 優惠券
export const getCoupon = data => userRequest.get('/api/coupon/search', data)
export const getShipMethod = data => userRequest.post('/api/shipmethod/search', data)
// 會員中心 - 地址
export const addAddress = data => userRequest.post('/api/user/addr/store', data)
export const delAddress = data => userRequest.post('/api/user/addr/delete', data)
export const getAddress = data => userRequest.get('/api/user/addr/search', data)
// 會員中心 - 會員資料
export const getUser = data => userRequest.get('/api/user/get', data)
export const freshRefcode = data => userRequest.post('/api/user/freshRefcode', data)
// 會員中心 - 匯款
export const getRef = data => userRequest.post('/api/user/ref/first', data)
export const getRefChildren = data => userRequest.post('/api/user/ref/child', data)
export const getRefInfo = data => userRequest.post('/api/user/ref/get', data)
// 會員中心 - 獎金資料
export const getPoint = data => userRequest.get('/api/point/search', data)
export const getMonth = data => userRequest.get('/api/point/month', data)
// 會員中心 - 雲倉庫存
export const getCloud = data => userRequest.get('/api/user/inventory/cloud', data)
export const getCloudLog = data => userRequest.get('/api/user/inventory/cloud/log', data)
// 會員中心 - 個人庫存
export const getUserCloud = data => userRequest.get('/api/seller/inventory', data)
export const getUserCloudLog = data => userRequest.get('/api/seller/inventory/log', data)
// 會員中心 - 申請提貨
export const takeoutHct = data => userRequest.post('/api/user/takeout/hct', data)
// 會員中心 - 雲庫存申請紀錄
export const cloudApplyLog = data => userRequest.get('/api/user/takeout/list', data)
// 會員中心 - 第三方綁定
export const threeInfo = data => userRequest.get('/api/user/threeway/get', data)
// 快速下單
export const getProduct = data => userRequest.get('/api/product/search/fast', data)
export const orderApiQuick = data => userRequest.post('/api/order/pay_info/fast', data)
export const finallOrderQuick = data => userRequest.post('/api/order/store/fast', data)
//會員中心 - 換貨
export const getRechangeSelf = data => userRequest.get('/api/user/rechange/self', data)
export const getRrechangeCompany = data => userRequest.get('/api/user/rechange/company', data)
export const goRechange = data => userRequest.post('/api/user/rechange/store', data)
export const getRechangeLog = data => userRequest.get('/api/user/rechange/list', data)
//會員中心 - 會員總攬
export const getInfo = data => userRequest.get('/api/user/verifyMonthMember', data)
export const changeUserName = data => userRequest.post('/api/user/updateNickname', data)
//通知中心
export const systemNotice = data => userRequest.get('/api/user/systemTabNotice', data)
//子商城
export const getSubmall = data => userRequest.post('/api/supmall/home', data)
export const checkSubmall = data => userRequest.post('/api/supmall/subroute', data)
//錢包
export const walletApply = data => userRequest.post('/api/user/wallet/apply', data)
export const walletLog = data => userRequest.get('/api/user/wallet/home', data)
//會員中心 - 分享專區
export const shareProduct = data => userRequest.get('/api/user/shareProduct', data)
export const productPercent = data => userRequest.get('/api/point/productPercent?product_id='+data)//營養值
export const teamPointPercent = data => userRequest.get('/api/point/teamPointPercent', data)//團隊獎金
//陌生客查詢訂單
export const orderSearch = data => userRequest.get('/api/supmall/orderDetail', data)
// 會員已登入的情況下  不給他進入非他推薦人的連結
export const subMallCheckOut = data => userRequest.post('/api/supmall/recheck', data)
export const mallCheckOut = data => userRequest.post('/api/supmall/recheck2', data)
export const updateBank = data => userRequest.post('/api/supmall/updateBank', data)
// 判斷註冊
export const isbindpost = data => userRequest.post('/api/auth/isbind', data)
export const isBankpost = data => userRequest.post('/api/supmall/isBank', data)


//會員中心 - 賣家訂單
export const memberSellOrder = data => userRequest.post('/api/seller/list', data)
export const memberSellOrderDetail = data => userRequest.post('/api/seller/detail', data)
export const memberSellhtcDetail = data => userRequest.get('/api/seller/hct_detail', data)
export const memberSellOrderPay = data => userRequest.post('/api/seller/updateSellerPayment', data)
export const memberSellOrderChooseShip = data => userRequest.post('/api/seller/updateShipMethod', data)
export const memberSellOrderSelfShip = data => userRequest.post('/api/seller/updateSelfShip', data)
export const memberSellOrderUpdateShip = data => userRequest.post('/api/seller/updateShipStatus', data)
export const memberSellOrderFinish = data => userRequest.post('/api/seller/updateFinish', data)


// 經銷商專區
export const dealerMainBanner = data => userRequest.get('/api/front/dealer/search', data)
export const dealerProductList = data => userRequest.get('/api/dealer/search', data)
export const dealerProductSubList = data => userRequest.get('/api/dealer/subSearch', data)
export const dealerProductDetail = data => userRequest.get('/api/dealer/get', data)

//

// detaildatas type==1申請換貨商品與數量, type==2換貨商品
